import { richText, stripRichTextWrapperTag } from '../../utils/html';

export default (data) => {
  const { primary } = data;
  return {
    narrowHero: true,
    title: stripRichTextWrapperTag(primary.seo_push_title),
    titleTag: 'h2',
    content: richText(primary.seo_push_description),
  };
};
